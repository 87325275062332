@import '../variables';

html {
  height: 100%;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
}

body {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  font-size: 14px;
}

/* stylelint-disable-next-line selector-class-pattern */
body.no-scroll {
  cdk-virtual-scroll-viewport {
    overflow: hidden;
  }
}

button,
input {
  appearance: none; // remove browser styling
}

/* stylelint-disable selector-class-pattern */
a.no-link-style {
  color: currentColor;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    color: currentColor;
  }
}
/* stylelint-enable selector-class-pattern */

// Needs to have a higher z-index than the navigation component to display the confirmation dialog correctly.
.cdk-overlay-container {
  z-index: 10001 !important;
}
